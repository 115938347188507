


















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { wxUrl } from '@/assets/js/common'
@Component
export default class WeChatCode extends Vue {
  code = ''
  created(): void {
    this.code = wxUrl()
  }
  // 登录
  cancel(): void {
    this.$router.push({ name: 'login' })
  }
}
